import React, { useState, useEffect } from "react";
import {
  AboutContainer,
  AboutH1,
  ImageContainer,
  AboutText,
  ImageRow,
  ImageWrapper,
  ArrowImage, // Remove the duplicate import
} from "./About.styled"; // Use the styled component from the styled file
import Owner from "../../images/owner.jpeg";
import B1 from "../../images/B1.png";
import B2 from "../../images/B2.png";
import B3 from "../../images/B3.png";
import B4 from "../../images/B4.png";
import B5 from "../../images/B5.png";
import Back from "../../images/Back.png";
import SE from "../../images/SE.jpg";
import Forward from "../../images/Forward.png";

const images = [B5, B4, B3, B1, B2];

const About = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const goToPrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <AboutContainer id="about">
      <AboutH1>Meet Our Accountant</AboutH1>
      <ImageContainer src={Owner} alt="Owner" />
      <AboutText>
        <strong> Merita Christine Perera (ACMA, CGMA) </strong>
        <br />
        <br />
        Merita Christine Perera carries the ACMA (Associate Chartered Management
        Accountant) and CGMA (Chartered Global Management Accountant)
        designations, bestowed upon her by the AICPA & CIMA.
      </AboutText>

      <AboutText>
        Our mission is to provide high quality Accounting & Bookkeeping services
        that help small-medium businesses in Canada and the USA to manage their
        finances more effectively.
      </AboutText>

      <AboutText>
        With our expertise and personalized approach, we ensure compliance,
        accuracy, and timely results. Our commitment to client satisfaction and
        tailored solutions sets us apart. Trust us to handle your financial
        needs while you focus on growing your business with confidence.
      </AboutText>

      <AboutText>
        MCP Accounting is a member of the London Chamber of Commerce. We have
        earned Digital Mindset Badge issued by AICPA & CIMA which has developed
        the capabilities and confidence needed to not just survive but thrive in
        the digital future. Also we are Certified Advanced Quickbooks Online
        ProAdvisors. We ensure that we serve our clients better with the best in
        the industry.
      </AboutText>
      <AboutH1>Meet Our Assistant Accountant</AboutH1>
      <AboutText>
        <strong> Felix Sarath Perera (Chartered Accountant) </strong>
        <br />
        <br />
        Felix Sarath Perera is a qualified Chartered Accountant in Sri Lanka,
        bringing a wealth of expertise in financial management and accounting
        practices. With a strong educational background and professional
        experience, he is dedicated to providing accurate and insightful
        financial analysis to help guide our business decisions. His commitment
        to excellence ensures that our accounting practices meet the highest
        standards of integrity and professionalism.
      </AboutText>

      <AboutH1>Meet Our Web Developer</AboutH1>
      <AboutText>
        <strong> Romain Perera (BSc (Hons) in Information Technology) </strong>
        <br />
        <br />
        Romain Perera is the talented web developer behind our website. With a
        background in front-end and back-end development, Romain brings a wealth
        of expertise in creating user-friendly, secure, and efficient websites
        tailored to clients' needs. He graduated with a BSc (Hons) in
        Information Technology from the University of Moratuwa, Sri Lanka. Her
        dedication to high-quality code and design ensures our online presence
        reflects our commitment to excellence.
      </AboutText>

      <ImageRow>
        <ArrowImage src={Back} alt="Previous" onClick={goToPrevImage} />
        {images.map((image, index) => {
          const isCurrent =
            index === currentImageIndex || index === currentImageIndex + 1;
          return (
            <ImageWrapper
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              style={{ display: isCurrent ? "block" : "none" }}
            />
          );
        })}
        <ArrowImage src={Forward} alt="Next" onClick={goToNextImage} />
      </ImageRow>
    </AboutContainer>
  );
};

export default About;
